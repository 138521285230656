import Multiselect from "vue-multiselect";

require('./bootstrap');

import Vue from 'vue/dist/vue.js';

import MultiImage from "vue-upload-multiple-image";
import VueMultiSelect from "vue-multiselect";

window.toastr = require('toastr');
window.Swal = require('sweetalert2');

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload)

Vue.component('multiple-image', MultiImage);
Vue.component('multiselect', VueMultiSelect);

require('bootstrap-datepicker');

import tinymce from 'tinymce';

/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';

/* A theme is also required */
import 'tinymce/themes/silver';

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist/plugin'
import 'tinymce/plugins/anchor/plugin'
import 'tinymce/plugins/autolink/plugin'
import 'tinymce/plugins/autoresize/plugin'
import 'tinymce/plugins/autosave/plugin'
import 'tinymce/plugins/charmap/plugin'
import 'tinymce/plugins/code/plugin'
import 'tinymce/plugins/codesample/plugin'
import 'tinymce/plugins/directionality/plugin'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/emoticons/plugin'
import 'tinymce/plugins/fullpage/plugin'
import 'tinymce/plugins/fullscreen/plugin'
import 'tinymce/plugins/help/plugin'
import 'tinymce/plugins/hr/plugin'
import 'tinymce/plugins/image/plugin'
import 'tinymce/plugins/imagetools/plugin'
import 'tinymce/plugins/importcss/plugin'
import 'tinymce/plugins/insertdatetime/plugin'
import 'tinymce/plugins/link/plugin'
import 'tinymce/plugins/lists/plugin'
import 'tinymce/plugins/media/plugin'
import 'tinymce/plugins/nonbreaking/plugin'
import 'tinymce/plugins/noneditable/plugin'
import 'tinymce/plugins/pagebreak/plugin'
import 'tinymce/plugins/paste/plugin'
import 'tinymce/plugins/preview/plugin'
import 'tinymce/plugins/print/plugin'
import 'tinymce/plugins/quickbars/plugin'
import 'tinymce/plugins/save/plugin'
import 'tinymce/plugins/searchreplace/plugin'
import 'tinymce/plugins/spellchecker/plugin'
import 'tinymce/plugins/table/plugin'
import 'tinymce/plugins/template/plugin'
import 'tinymce/plugins/textpattern/plugin'
import 'tinymce/plugins/toc/plugin'
import 'tinymce/plugins/visualblocks/plugin'
import 'tinymce/plugins/visualchars/plugin'
import 'tinymce/plugins/wordcount/plugin'
import 'tinymce/icons/default'

import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
import contentCss from 'tinymce/skins/content/default/content.css';

window.Vue = Vue;

$(() => {

    $(".sidebar-dropdown > a").click(function() {
        $(".sidebar-submenu").slideUp(200);
        if (
            $(this)
                .parent()
                .hasClass("active")
        ) {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
                .parent()
                .removeClass("active");
        } else {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
                .next(".sidebar-submenu")
                .slideDown(200);
            $(this)
                .parent()
                .addClass("active");
        }
    });

    $("#close-sidebar").click(function() {
        $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function() {
        $(".page-wrapper").addClass("toggled");
    });
});
